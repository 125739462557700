<template>
  <div id="app">
    <div class="content">
      <div class="content-main" v-if="user">
        <div class="padding">
          <p class="logged-in-as">
            "Logged in as {{ user.name }} on {{ user.organization }}"
          </p>

          <hr />

          <h4>Subject</h4>
          <p class="small">{{ item.subject }}</p>
          <h4>From</h4>
          <p class="small">
            {{ item.sender.displayName }} <{{ item.sender.emailAddress }}>
          </p>

          <hr />
          <p class="small"></p>

          <div v-if="completed">
            <DataSentPage
              :user="user"
              :endpoint="endpoint"
              :item="item"
              :completed="completed"
              type="activity"
            />
          </div>
          <div v-else-if="loading" class="loading-box-activity">
            <spinner></spinner>
          </div>

          <div v-else>
            <label class="notes-label optional">Notes</label>
            <textarea class="textarea-item" v-model="item.notes"></textarea>

            <SearchCompany :endpoint="endpoint" v-model="company" />

            <multiselect
              class="multiselect-engagements"
              :disabled="!company"
              v-model="multiselectEng"
              :options="engagements"
              :searchable="true"
              :close-on-select="true"
              :show-labels="false"
              :max-height="200"
              track-by="id"
              label="name"
              placeholder="Search your engagements"
            ></multiselect>

            <select
              name="engagement"
              v-model="selectedEngagement"
              style="display: none"
            >
              <option value="0">No engagement</option>
              <option :value="eng.id" v-for="eng in this.engagements">{{
                eng.name
              }}</option>
            </select>

            <!--<select
              name="engagement_company"
              v-if="
                selectedEngagementObject && selectedEngagementObject.companies
              "
              v-model="selectedCompany"
            >
              <option value="0" disabled>-- Select Company</option>
              <option
                :value="company.id"
                v-for="company in this.selectedEngagementObject.companies"
                >{{ company.name }}</option
              > -->
            <!-- </select> -->

            <div class="input-radio-container">
              <input
                type="radio"
                id="activity_type_0"
                v-model="item.activity_type"
                name="activity_type"
                value="0"
              />
              <label class="label-radio" for="activity_type_0"
                >Internal activity</label
              >
              <br />
              <input
                type="radio"
                id="activity_type_1"
                v-model="item.activity_type"
                name="activity_type"
                value="1"
              />
              <label class="label-radio" for="activity_type_1"
                >External Activity</label
              >
            </div>

            <button class="btn-send btn-primary" @click="postForm()">
              Send to glass lewis
            </button>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import DataSentPage from "./DataSentPage.vue";
import SearchCompany from "./SearchCompany.vue";
import VueToast from "vue-toast-notification";

const axios = require("axios");

export default {
  components: { DataSentPage, SearchCompany },
  name: "App",
  props: ["user", "endpoint", "item"],
  data: function() {
    return {
      multiselectEng: null,
      company: null,
      page: null,
      error: {
        type: null,
        message: null,
      },
      loading: false,
      completed: null,
      engagements: [],
      selectedEngagement: 0,
    };
  },
  watch: {
    /*     company: function(company) {
      this.engagements = this.engagements.filter((e) => e.company === company);
      this.selectedEngagement = null;
    }, */

    selectedEngagement: function(val) {
      this.item.engagement_id = val;
    },
    /*   selectedCompany: function(val) {
      this.item.sub_engagement_id = val;
    }, */

    multiselectEngString: function(val) {
      this.selectedEngagement = this.multiselectEng.id;
    },

    multiselectEng: function(val) {
      if (!val) {
        this.selectedEngagement = null;
      } else {
        this.selectedEngagement = val.id;
      }
    },

    company: function(company) {
      this.selectedEngagement = null;
      this.multiselectEng = null;
      if (!company) {
        this.item.company = null;
      } else {
        this.item.company = company.id;
        console.log(this.item);
        this.getEngagements(company);
      }
    },
  },

  computed: {
    /*     selectedEngagementObject: function() {
      if (this.selectedEngagement === null) {
        return null;
      }
      return this.engagements.filter(
        (e) => e.id === this.selectedEngagement
      )[0];
    }, */

    /*     selectedEngagementObject: function() {
      return this.engagements.filter(
        (e) => e.id === this.selectedEngagement
      )[0];
    }, */

    multiselectEngString: function() {
      return JSON.stringify(this.multiselectEng);
    },
  },
  methods: {
    postForm: function() {
      let _this = this;
      console.log("post", _this.item);
      _this.loading = true;
      axios
        .post(`${this.endpoint.url}/api/plugins/outlook/post`, _this.item, {
          headers: {
            Authorization: "Bearer " + _this.endpoint.apikey,
          },
        })
        .then(function(response) {
          _this.loading = false;
          _this.item.notes = null;
          _this.completed = response.data.data;
          plausible("Send email to Glass Lewis with Outlook Addin");
        })
        .catch(function(error) {
          _this.loading = false;
          _this.error = {
            type: "formpost",
            message:
              "Something went wrong with posting the data to Glass Lewis, please try again later or contact Glass Lewis support.",
          };
        });
    },
    getEngagements: function(company) {
      let _this = this;
      console.log(_this.company);
      axios
        .get(`${this.endpoint.url}/api/company/${company.id}/engagements`, {
          headers: {
            Authorization: "Bearer " + this.endpoint.apikey,
          },
        })
        .then(function(response) {
          _this.engagements = response.data.data;
          console.log(response.data.data);
        });
    },
  },
  mounted() {
    console.log("mount activity");
  },
};
</script>
