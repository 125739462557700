import Vue from 'vue';
import App from './App.vue';
import 'vue-toast-notification/dist/theme-sugar.css';
import Multiselect from 'vue-multiselect'

Vue.config.productionTip = false;
Vue.component('spinner', require('./components/SpinnerComponent.vue').default)
Vue.component('multiselect', Multiselect)

window.Office.initialize = () => {
	window.Office.onReady().then(function(){
		new Vue({
			render: h => h(App)
		}).$mount('#app');
	});
	
};
