<template>
  <div id="app">
    <div class="content-main">
      <div class="padding">
        <div v-if="completed">
          <DataSentPage
            :user="user"
            :endpoint="endpoint"
            :item="form"
            :completed="completed"
            type="engagement"
          />
        </div>
        <div v-else-if="loading" class="loading-box-engagement">
          <spinner></spinner>
        </div>

        <div v-else>
          <h4 class="create-engagement-header">
            Create engagement
          </h4>
          <div class="box">
            <div class="single-box">
              <label class="eng-label required" for="date">Date</label>
              <input
                name="date"
                v-model="form.created_at"
                type="date"
                required
                class="date-form"
              />
            </div>
            <p
              class="single-error-message"
              v-for="(error, index) in errors.created_at"
              :key="index"
            >
              {{ error }}
            </p>

            <div class="single-box">
              <label class="eng-label required" for="company"
                >Counterparty</label
              >

              <SearchCompany
                :endpoint="endpoint"
                v-model="form.company"
                @input="updateCompany(form.company)"
              />
              <!--  {{ form.company.name }} -->
            </div>
            <p
              class="single-error-message"
              v-for="(error, index) in errors.company"
              :key="index"
            >
              {{ error }}
            </p>

            <div class="single-box">
              <label class="eng-label required" for="esg-issue"
                >Engagement topic</label
              >
              <multiselect
                class="multiselect-topics"
                v-model="form.topic"
                :options="esg_issues"
                :searchable="true"
                :multiple="false"
                :required="true"
                :close-on-select="true"
                :show-labels="false"
                :max-height="200"
                track-by="issue"
                label="issue"
                placeholder="Select engagement topic"
              ></multiselect>
            </div>
            <p
              class="single-error-message"
              v-for="(error, index) in errors.topic"
              :key="index"
            >
              {{ error }}
            </p>

            <div class="single-box">
              <label class="eng-label optional" for="esg-category"
                >ESG category</label
              >
              <multiselect
                class="multiselect-categories"
                v-model="form.esgs"
                :options="esgs"
                :searchable="true"
                :multiple="true"
                :close-on-select="true"
                :show-labels="false"
                :max-height="200"
                track-by="id"
                label="name"
                placeholder="Select ESG category"
              >
              </multiselect>
            </div>

            <div class="single-box">
              <label class="eng-label optional" for="main-objective"
                >Objective</label
              >
              <textarea
                class="textarea-objective"
                v-model="form.objective"
              ></textarea>
            </div>

            <p
              class="single-error-message"
              v-for="(error, index) in errors.objective"
              :key="index"
            >
              {{ error }}
            </p>
            <button
              type="submit"
              class="btn-create btn-primary"
              @click="submitEngagement()"
            >
              Create engagement
            </button>
						<p class="small" style="margin-top: 20px;">
							* All engagements created from our Add-in for Outlook will be using the "Standard" engagement template.
						</p>
            <p class="error-message" v-if="formErrorText">
              {{ formErrorText }}
            </p>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
const axios = require("axios");
import SearchCompany from "./SearchCompany.vue";
import DataSentPage from "./DataSentPage.vue";
import Multiselect from "vue-multiselect";

export default {
  components: { SearchCompany, Multiselect, DataSentPage },
  name: "App",
  props: ["user", "endpoint", "item"], //"companies"
  data: function() {
    return {
      errors: [],
      company: null,
      /*      companies: [],
      multiselect: {
        companies: "",
      }, */
      page: null,
      loading: false,
      errors: [],
      formErrorText: "",
      error: {
        type: null,
        message: null,
      },
      form: {
        esgs: [],
        company: {},
        fundIds: [],
        created_at: new Date().toISOString().slice(0, 10),
        objective: null,
        topic: null,
        ownerId: null, // todo: check if we need this, might add owner_id in be from post
      },
      esgs: [],
      esg_issues: [],
      loading: false,
      completed: null,
      engagements: [],
      selectedEngagement: 0,
    };
  },

  watch: {
    /*     company: function(company) {
      if (!company) {
        this.form.company = {};
      } else {
        this.form.company = company.id;
        console.log(this.company);
        this.updateCompany(company, "set company?");
      }
    }, */
    "form.objective": function() {
      if (this.form.objective.length >= 5) {
        this.errors.objective = [];
      }
      this.requiredFieldsValued();
    },
    "form.created_at": function() {
      this.errors.created_at = [];
      this.requiredFieldsValued();
    },
    "form.company": function() {
      this.errors.company = [];
      this.requiredFieldsValued();
    },
    "form.topic": function() {
      this.errors.topic = [];
      this.requiredFieldsValued();
    },
  },

  methods: {
    requiredFieldsValued() {
      if (Object.values(this.errors).every((arr) => arr.length === 0)) {
        this.formErrorText = "";
      }
    },

    //Den jag väljer i multiselecten hamnar i company
    updateCompany(company) {
      this.form.company = company;
      console.log("updateCompany", company);
      this.form.fundIds = this.form.company.funds.map((fund) => fund.id);
      console.log("funds in company", this.form.fundIds);
    },

    getEsgCategories() {
      let _this = this;
      axios
        .get(`${this.endpoint.url}/api/esgs/`, {
          headers: {
            Authorization: "Bearer " + this.endpoint.apikey,
          },
        })
        .then(function(response) {
          _this.form.esgs = _this.esgs;
          _this.esgs = response.data;
          /*  console.log("esgs", response.data); */
        })
        .catch((error) => {
          console.log(error);
        });
    },

    getEsgIssues() {
      let _this = this;
      axios
        .get(`${this.endpoint.url}/api/organizations/esgissue`, {
          headers: {
            Authorization: "Bearer " + this.endpoint.apikey,
          },
        })
        .then(function(response) {
          _this.esg_issues = response.data;
          console.log("esgIssues", response.data);
        })
        .catch((error) => {
          console.log(error);
        });
    },

    submitEngagement() {
      let _this = this;
      _this.loading = true;
      console.log("postForm", _this.form);
      _this.form.ownerId = this.user.id;

      axios
        .post(`${this.endpoint.url}/api/engagements`, _this.form, {
          headers: {
            Authorization: "Bearer " + _this.endpoint.apikey,
          },
        })
        .then(function(response) {
          _this.loading = false;
          _this.completed = response.data.data;
          plausible(
            "Send created engagement to Glass Lewis with Outlook Addin"
          );
        })

        .catch((error) => {
          _this.loading = false;
          this.onError(error);
          console.log(_this.form.company, "what in this form");
        });
    },
    onError: function(error) {
      this.loading = false;
      this.errors = error.response.data.errors;
      console.log(error.response.data.errors, "errors");
      this.formErrorText =
        "Something went wrong. Make sure you filled out all required fields.";
    },
  },

  mounted() {
    console.log("mount engagement");
    this.getEsgCategories();
    this.getEsgIssues();
  },
};
</script>
